<template>
  <div class="card">
    <div class="card-header">
      <h2 class="card-header-title">Modifier la configuration</h2>
    </div>
    <div class="card-content columns is-multiline">
      <b-field
        class="column is-half is-full-mobile"
        label="Intervalle en seconde"
        :message="fields.interval.error"
        :type="fields.interval.error !== null ? 'is-danger' : ''"
      >
        <b-numberinput
          v-model="fields.interval.value"
          expanded
          controls-alignment="right"
          controls-position="compact"
          :loading="loading"
          :min="1"
        />
      </b-field>
      <b-field class="column is-12">
        <b-button type="is-primary" :loading="loading" :disabled="disabledSubmit" @click="submit">
          Modifier
        </b-button>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'QueclinkTrackerConfigForm',

  props: {
    queclinkTrackerConfig: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      fields: {
        interval: {
          value: 60,
          error: null,
        },
      },
    }
  },

  watch: {
    'fields.interval.value'(value) {
      this.fields.interval.error =
        Number(value) >= 1 ? null : "L'intervalle doit être supérieur ou égal à 1 seconde"
    },

    'queclinkTrackerConfig.interval': {
      handler(interval) {
        if (interval) {
          this.fields.interval.value = interval
        }
      },
      immediate: true,
    },
  },

  computed: {
    disabledSubmit() {
      return this.loading || this.fields.interval.error !== null
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        const queclinkTrackerConfig = await this.$services.queclinkTrackerConfigService.update({
          ...this.queclinkTrackerConfig,
          interval: this.fields.interval.value,
        })

        this.$emit('update:queclinkTrackerConfig', queclinkTrackerConfig)

        this.addToastMessage({
          text: 'Configuration mise à jour',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },
  },
}
</script>
