<template>
  <b-loading v-if="loading" active />
  <b-message v-else-if="!queclinkTrackerConfig" type="is-danger">
    Aucune configuration associée
  </b-message>
  <div v-else>
    <queclink-tracker-config-information
      :queclink-tracker="queclinkTracker"
      :queclink-tracker-config.sync="queclinkTrackerConfig"
    />
    <queclink-tracker-config-form
      class="mt-5"
      :queclink-tracker-config.sync="queclinkTrackerConfig"
    />
  </div>
</template>

<script>
import QueclinkTrackerConfigInformation from './QueclinkTrackerConfigInformation.vue'
import QueclinkTrackerConfigForm from './QueclinkTrackerConfigForm.vue'
import { mapActions } from 'vuex'

const LOAD_INTERVAL_IN_SECONDS = 5

export default {
  name: 'QueclinkTrackerEndpoint',

  components: { QueclinkTrackerConfigInformation, QueclinkTrackerConfigForm },

  props: {
    queclinkTracker: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      intervalId: null,
      queclinkTrackerConfig: null,
    }
  },

  async created() {
    this.loading = true
    await this.load()
    this.loading = false

    this.intervalId = window.setInterval(async () => {
      await this.load()
    }, LOAD_INTERVAL_IN_SECONDS * 1000)
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async load() {
      try {
        this.queclinkTrackerConfig = await this.$services.queclinkTrackerConfigService.getById(
          this.queclinkTracker.id,
        )
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
