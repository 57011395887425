<template>
  <div class="card">
    <div class="card-header is-flex is-justify-content-space-between is-align-items-center">
      <h2 class="card-header-title">Informations</h2>
      <b-tooltip :label="resyncTooltip" position="is-left">
        <b-button
          class="is-success mr-2"
          size="is-small"
          :label="
            resyncRemainingTime ? `Resynchroniser (${resyncRemainingTime}s)` : 'Resynchroniser'
          "
          type="is-danger"
          icon-right="sync"
          :disabled="disabledResync"
          :loading="loading"
          @click="resync"
        />
      </b-tooltip>
    </div>
    <div class="card-content">
      <table class="table is-striped is-fullwidth">
        <tbody>
          <tr>
            <th>Initialisé</th>
            <td v-if="queclinkTrackerConfig.isInitialized">
              <b-icon size="is-small" type="is-success" icon="check" />
              Depuis le {{ format(queclinkTrackerConfig.initializedAt) }}
            </td>
            <td v-else>
              <b-icon size="is-small" type="is-danger" icon="times" />
            </td>
          </tr>
          <tr>
            <th>Synchronisé</th>
            <td v-if="queclinkTrackerConfig.isProcessing">
              <b-icon size="is-small" type="is-info" icon="hourglass-half" />
              En cours depuis le {{ format(queclinkTrackerConfig.synchronizedAt) }}
            </td>
            <td v-else-if="queclinkTrackerConfig.synchronizedAt">
              <b-icon size="is-small" type="is-success" icon="check" />
              Depuis le {{ format(queclinkTrackerConfig.synchronizedAt) }}
            </td>
            <td v-else>
              <b-icon size="is-small" type="is-danger" icon="times" />
            </td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{{ getStatus(queclinkTrackerConfig.syncStatus) }}</td>
          </tr>
          <tr>
            <th>Intervalle en secondes</th>
            <td>{{ queclinkTrackerConfig.interval || '–' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { QUECLINK_TRACKER_CONFIG_SYNC_STATUS } from '@constants/queclinkTrackerConfig/syncStatus'
import { mapActions } from 'vuex'
import { format } from 'date-fns'

// Minimum time between each resync
const RESYNC_COUNTDOWN_IN_SECONDS = 180
const RESYNC_COUNTDOWN_INTERVAL_IN_SECONDS = 1
const QUECLINK_TRACKER_CONFIG_RESYNC_TIMESTAMP = 'queclink_tracker_config_resync_timestamp'

export default {
  name: 'QueclinkTrackerConfigInformation',

  props: {
    queclinkTracker: {
      type: Object,
      required: true,
    },
    queclinkTrackerConfig: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      resyncInterval: null,
      resyncRemainingTime: 0,
    }
  },

  async created() {
    if (this.queclinkTrackerConfig.isProcessing) {
      this.startResyncCountdownFromLocalStorage()
    }
  },

  beforeDestroy() {
    window.clearInterval(this.resyncInterval)
  },

  watch: {
    queclinkTrackerConfig: {
      handler(newConfig, oldConfig) {
        if (
          newConfig.isSynchronized ||
          newConfig.interval !== oldConfig.interval ||
          (!newConfig.synchronizedAt && oldConfig.synchronizedAt)
        ) {
          this.resetResyncCountdown()
        }
      },
      deep: true,
    },
  },

  computed: {
    disabledResync() {
      return !this.queclinkTracker.emnifyEndpointId || this.resyncRemainingTime > 0
    },

    resyncTooltip() {
      if (!this.queclinkTracker.emnifyEndpointId) {
        return 'Aucun endpoint Emnify associé'
      }

      return this.resyncRemainingTime
        ? 'Synchronisation en cours...'
        : 'Envoyer un SMS pour forcer la configuration'
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async resync() {
      this.loading = true

      try {
        const queclinkTrackerConfig = await this.$services.queclinkTrackerConfigService.resync(
          this.queclinkTracker.id,
        )

        this.startResyncCountdown(RESYNC_COUNTDOWN_IN_SECONDS)

        this.$emit('update:queclinkTrackerConfig', queclinkTrackerConfig)

        this.addToastMessage({
          text: 'Resynchronisation en cours...',
          type: 'is-info',
        })
      } catch (err) {
        this.addToastMessage({
          text: err?.message || 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    getStatus(status) {
      const syncStatus = QUECLINK_TRACKER_CONFIG_SYNC_STATUS[status]

      return syncStatus ?? 'Aucune configuration'
    },

    format(date) {
      return format(date, 'dd/MM/yyyy HH:mm')
    },

    startResyncCountdown(remainingTime) {
      localStorage.setItem(
        QUECLINK_TRACKER_CONFIG_RESYNC_TIMESTAMP,
        new Date().getTime() + remainingTime * 1000,
      )

      this.resyncRemainingTime = remainingTime

      if (this.resyncInterval) {
        window.clearInterval(this.resyncInterval)
      }

      this.resyncInterval = window.setInterval(() => {
        if (this.resyncRemainingTime <= 0) {
          window.clearInterval(this.resyncInterval)
          return
        }

        this.resyncRemainingTime--
      }, RESYNC_COUNTDOWN_INTERVAL_IN_SECONDS * 1000)
    },

    resetResyncCountdown() {
      this.resyncRemainingTime = 0
      window.clearInterval(this.resyncInterval)
      localStorage.removeItem(QUECLINK_TRACKER_CONFIG_RESYNC_TIMESTAMP)
    },

    startResyncCountdownFromLocalStorage() {
      const timestamp = Number(localStorage.getItem(QUECLINK_TRACKER_CONFIG_RESYNC_TIMESTAMP))
      if (!timestamp) {
        return
      }

      const remainingTime = Math.floor((timestamp - new Date().getTime()) / 1000)
      if (remainingTime <= 0) {
        return
      }

      this.startResyncCountdown(remainingTime)
    },
  },
}
</script>
