<template>
  <div class="level">
    <div class="level-left">
      <p class="level-item">
        <b-button tag="router-link" type="is-success" :to="{ name: 'queclinkTracker.add' }">
          <b-icon icon="plus" size="is-small" />
          <span>Nouveau</span>
        </b-button>
      </p>
    </div>
    <div class="level-right">
      <p class="level-item">
        <import-csv
          :format="csvHeader"
          :loading="loading"
          label="Modifier les propriétaires (CSV)"
          @submit="$emit('import', $event)"
        />
      </p>
      <p class="level-item">
        <b-button
          type="is-outlined"
          class="has-background-light"
          icon-left="file-export"
          :disabled="loading || queclinkTrackers.length === 0"
          label="Export CSV"
          @click="exportCsv"
        />
      </p>
    </div>
  </div>
</template>

<script>
import ImportCsv from '@components/ImportCsv.vue'
import { AVAILABILITIES } from '@constants/tracker/availability'
import { downloadFile } from '@helpers/downloadFile'
import { formatCSV } from '@helpers/csv'

export default {
  name: 'ListQueclinkTrackersAction',

  components: {
    ImportCsv,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    queclinkTrackers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      csvHeader: {
        line: 'Line',
        number: 'Number',
        owner: 'Owner',
      },
    }
  },

  methods: {
    exportCsv() {
      const data = this.queclinkTrackers.map((tracker, index) => [
        index + 1,
        tracker.number,
        tracker.imei,
        AVAILABILITIES[tracker.availability],
        tracker.owner,
      ])
      data.unshift(['Line', 'Number', 'IMEI', 'Status', 'Owner'])

      downloadFile(formatCSV(data), 'csv', 'queclink-trackers')
    },
  },
}
</script>
